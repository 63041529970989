import React, { useState, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// Component
import ProjectHero from "../../section/projectpage/projectpage-hero"
import { BlockFullImage, BlockWideImage } from "../../components/project/image"
import Divider from "../../components/divider"
import ProjectAbout from "../../section/projectpage/projectpage-about"
import {
  OneColumnHeading,
  OneColumnTitle,
  OneColumnElementTitle,
} from "../../components/project/one-column"
import {
  TwoColumnHeading,
  TwoColumnTitle,
} from "../../components/project/two-column"
import Quote from "../../components/project/quote"
import { Display, DisplaySizePlus } from "../../components/project/display"
import ProjectNext from "../../section/projectpage/projectpage-next"
// Layout
import LayoutWrapper from "../../layouts/wrapper"
import ScrollWrapper from "../../layouts/scroll-wrapper"
import Metadata from "../../layouts/metadata"
import Header from "../../layouts/header"
import { Container, ProjectContainer } from "../../layouts/container"
import Footer from "../../layouts/footer"
import Social from "../../layouts/social"
import Video, { VimeoVideo } from "../../components/project/video"

// Data
import useSpaceCenterData from "../../hook/projects/space-center-data"
import assetsVideo1 from "../../assets/video/space-center/Example.mp4"
import assetsVideo2 from "../../assets/video/space-center/sample(complete_.mp4"
// Style
import oneColumnStyle from "../../style/components/projects/one-column.module.scss"

export default function Template() {
  // MENU: Data
  const {
    about_content,
    about_title,
    hero_image,
    project_description,
    project_name,
    role,
    timeline,
    tool,
    type,
    order1,
    body,
    body1,
  } = useSpaceCenterData()

  const ProjectHeroData = {
    num: `0${Number(order1.text)}`,
    image_url: hero_image.fluid,
    name: project_name.raw,
    description: project_description.text,
  }
  const ProjectAboutData = {
    subtitle: "Overview",
    title: about_title.text,
    content: about_content.text,
    timeline: timeline.text,
    type: type.text,
    role: role.text,
    tool: tool.text,
  }
  const SliceData = body
  const SliceData2 = body1

  // MENU: Project Content

  const RoleData = {
    num: SliceData[0].primary.num.text,
    heading: SliceData[0].primary.heading.text,
    content: SliceData[0].items[0].content.text,
    listTitle: SliceData[0].items[1].title.text,
    listContent: SliceData[0].items[1].content.text,
    listTitle2: SliceData[0].items[2].title.text,
    listContent2: SliceData[0].items[2].content.text,
    listTitle3: SliceData[0].items[3].title.text,
    listContent3: SliceData[0].items[3].content.text,
    image_url: SliceData[1].items[0].full_image.fluid,
    image_alt: SliceData[1].items[0].full_image.alt,
  }
  const OppData = {
    num: SliceData[2].primary.num.text,
    heading: SliceData[2].primary.heading.text,
    title: SliceData[2].items[0].title.text,
    content: SliceData[2].items[0].content.text,
    content2: SliceData[2].items[1].content.text,
  }
  const FieldData = {
    title: SliceData[2].items[2].title.text,
    content: SliceData[2].items[2].content.text,
    image_url: SliceData[3].items[0].image.fluid,
    image_alt: SliceData[3].items[0].image.alt,
  }
  const ConceptData = {
    num: SliceData[4].primary.num.text,
    heading: SliceData[4].primary.heading.text,
    content: SliceData[4].items[0].content.text,
    underline: SliceData[4].items[1].content.text,
    content2: SliceData[4].items[2].content.text,
    quote: SliceData[5].primary.quote.text,
  }
  const HardwaretData = {
    title: SliceData[6].items[0].title.text,
    content: SliceData[6].items[0].content.text,
    content2: SliceData[6].items[1].content.text,
    image_url: SliceData[7].items[0].image.fluid,
    image_alt: SliceData[7].items[0].image.alt,
  }
  const ChallengeData = {
    num: SliceData[8].primary.num.text,
    heading: SliceData[8].primary.heading.text,
    content: SliceData[8].items[0].content.text,
  }

  // MENU: Project Design
  const ResearchData = {
    num: SliceData2[0].primary.num.text,
    heading: SliceData2[0].primary.heading.text,
    content: SliceData2[0].items[0].content.text,
    content2: SliceData2[0].items[1].content.text,
    content3: SliceData2[0].items[2].content.text,
    content4: SliceData2[0].items[3].content.text,
    content5: SliceData2[0].items[4].content.text,
    image_url: SliceData2[1].items[0].image.fluid,
    image_alt: SliceData2[1].items[0].image.alt,
  }
  const InterfaceData = {
    num: SliceData2[2].primary.num.text,
    heading: SliceData2[2].primary.heading.text,
    title: SliceData2[2].items[0].title.text,
    content: SliceData2[2].items[0].content.text,
    content1: SliceData2[2].items[1].content.text,
    content2: SliceData2[2].items[2].content.text,
    content3: SliceData2[2].items[3].content.text,
    image_url: SliceData2[3].items[0].image.fluid,
    image_alt: SliceData2[3].items[0].image.alt,
    image_url2: SliceData2[3].items[1].image.fluid,
    image_alt2: SliceData2[3].items[1].image.alt,
    image_url3: SliceData2[3].items[2].image.fluid,
    image_alt3: SliceData2[3].items[2].image.alt,
    image_url4: SliceData2[3].items[3].image.fluid,
    image_alt4: SliceData2[3].items[3].image.alt,
    image_url5: SliceData2[3].items[4].image.fluid,
    image_alt5: SliceData2[3].items[4].image.alt,
    image_url6: SliceData2[3].items[5].image.fluid,
    image_alt6: SliceData2[3].items[5].image.alt,
  }
  const OutcomeData = {
    title: SliceData2[4].items[0].title.text,
    image_url: SliceData2[5].items[0].image.fluid,
    image_alt: SliceData2[5].items[0].image.alt,
    image_url2: SliceData2[5].items[1].image.fluid,
    image_alt2: SliceData2[5].items[1].image.alt,
  }
  // darkmode
  const [isDark, setIsDark] = useState(false)
  function toggleDarkMode(isActive) {
    setIsDark(isActive)
  }

  // Animation
  useEffect(() => {
    const layoutWrapper = document.querySelector("#layoutWrapper")
    const bgColorChange = document.querySelector("#bgColorChange")

    gsap.timeline({
      ease: "none",
      scrollTrigger: {
        id: "bgColorChangeNavy",
        trigger: bgColorChange,
        start: "top+=500 center",
        end: "bottom+=1000 top",
        markers: false,
        toggleClass: {
          targets: layoutWrapper,
          className: "bg-navy",
        },
        onToggle: ({ isActive }) => toggleDarkMode(isActive),
      },
    })

    return () => {
      if (ScrollTrigger.getById("bgColorChangeNavy")) {
        ScrollTrigger.getById("bgColorChangeNavy").kill()
      }
    }
  }, [])

  return pug`
    Metadata(title=project_name.text)
    Header(darkmode=isDark)
    Social
    main
      ProjectHero(num=ProjectHeroData.num image_url=ProjectHeroData.image_url name=ProjectHeroData.name description=ProjectHeroData.description)
      Container.flex.justify-center.items-center.flex-col
        // About
        ProjectAbout(subtitle=ProjectAboutData.subtitle title=ProjectAboutData.title content=ProjectAboutData.content timeline=ProjectAboutData.timeline type=ProjectAboutData.type role=ProjectAboutData.role tool=ProjectAboutData.tool)

        ProjectContainer
          // Role
          OneColumnHeading(num=RoleData.num heading=RoleData.heading content=RoleData.content)
            OneColumnElementTitle.my-8(title=RoleData.listTitle content=RoleData.listContent)
            OneColumnElementTitle.my-8(title=RoleData.listTitle2 content=RoleData.listContent2)
            OneColumnElementTitle.my-8(title=RoleData.listTitle3 content=RoleData.listContent3)
          BlockFullImage(image_url=RoleData.image_url image_alt=RoleData.image_alt)

          //Opportunity & Research
          OneColumnHeading(num=OppData.num heading=OppData.heading)
            DisplaySizePlus.mt-4(mark=OppData.content style="txt-full-highlight-dark-secondary-light1")
            OneColumnElementTitle(content=OppData.content2)

          //- Field Research
          OneColumnTitle(title=FieldData.title content=FieldData.content)
          BlockWideImage(image_url=FieldData.image_url image_alt=FieldData.image_alt)

          //Design Concept
          OneColumnHeading(num=ConceptData.num heading=ConceptData.heading content=ConceptData.content)
            Display(className=oneColumnStyle.content style="txt-under-highlight-secondary" mark=ConceptData.underline)
            DisplaySizePlus.mt-4(mark=ConceptData.quote style="txt-full-highlight-dark-secondary-light1")
            // Display.mt-4(className=oneColumnStyle.content style="txt-under-highlight-secondary" mark=ConceptData.quote)
            OneColumnElementTitle(content=ConceptData.content2)

          //- Hardware
          OneColumnTitle(title=HardwaretData.title content=HardwaretData.content)
            OneColumnElementTitle(content=HardwaretData.content2)
          BlockWideImage(image_url=HardwaretData.image_url image_alt=HardwaretData.image_alt)

          //Design Challenge
          OneColumnHeading(num=ChallengeData.num heading=ChallengeData.heading title=ChallengeData.title content=ChallengeData.content)

          //Research
          OneColumnHeading(num=ResearchData.num heading=ResearchData.heading content=ResearchData.content)
            OneColumnElementTitle.my-8(content=ResearchData.content2)
            OneColumnElementTitle.my-8(content=ResearchData.content3)
            OneColumnElementTitle.my-8(content=ResearchData.content4)
            OneColumnElementTitle.my-8(content=ResearchData.content5)
          BlockWideImage(image_url=ResearchData.image_url image_alt=ResearchData.image_alt)

          //Final Present
          div#bgColorChange
            //- Interface
            OneColumnHeading(num=InterfaceData.num heading=InterfaceData.heading title=InterfaceData.title darkmode=isDark)
            BlockWideImage(image_url=InterfaceData.image_url image_alt=InterfaceData.image_alt)
            BlockWideImage(image_url=InterfaceData.image_url2 image_alt=InterfaceData.image_alt2)
            BlockWideImage(image_url=InterfaceData.image_url3 image_alt=InterfaceData.image_alt3)
            OneColumnElementTitle.my-8(content=InterfaceData.content darkmode=isDark)
            BlockWideImage(image_url=InterfaceData.image_url4 image_alt=InterfaceData.image_alt4)
            OneColumnElementTitle.my-8(content=InterfaceData.content1 darkmode=isDark)
            BlockWideImage(image_url=InterfaceData.image_url5 image_alt=InterfaceData.image_alt5)
            OneColumnElementTitle.my-8(content=InterfaceData.content2 darkmode=isDark)
            BlockWideImage(image_url=InterfaceData.image_url6 image_alt=InterfaceData.image_alt6)
            OneColumnElementTitle.my-8(content=InterfaceData.content3 darkmode=isDark)

            //-Outcome
            OneColumnTitle.mt-40(title=OutcomeData.title darkmode=isDark)
              Video(className="md:pt-2" src=assetsVideo1 alt="Final Present Video")
              Video(className="md:pt-2" src=assetsVideo2 alt="Gaming Screen Record")
            BlockWideImage(image_url=OutcomeData.image_url image_alt=OutcomeData.image_alt)
            BlockWideImage(image_url=OutcomeData.image_url2 image_alt=OutcomeData.image_alt2)

      ProjectNext(index=0)

  `
}
