import { useStaticQuery, graphql } from "gatsby"

const useSpaceCenterData = () => {
  const { SpaceCenterData } = useStaticQuery(
    graphql`
      query {
        SpaceCenterData: prismicProjectPage(uid: { eq: "space-center" }) {
          id
          uid
          data {
            about_content {
              text
              raw
            }
            about_title {
              text
              raw
            }
            hero_image {
              fluid {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            body {
              ... on PrismicProjectPageBodyOneColumn {
                id
                slice_type
                items {
                  content {
                    raw
                    text
                  }
                  title {
                    raw
                    text
                  }
                }
                primary {
                  heading {
                    raw
                    text
                  }
                  num {
                    raw
                    text
                  }
                }
              }
              ... on PrismicProjectPageBodyTwoColumn {
                id
                slice_type
                items {
                  content {
                    raw
                    text
                  }
                  title {
                    raw
                    text
                  }
                }
                primary {
                  heading {
                    raw
                    text
                  }
                  image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  num {
                    raw
                    text
                  }
                }
              }
              ... on PrismicProjectPageBodyFullimage {
                id
                slice_type
                items {
                  full_image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
              }
              ... on PrismicProjectPageBodyImageGallery {
                id
                slice_type
                items {
                  image {
                    fluid(maxWidth: 1200) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
              }
              ... on PrismicProjectPageBodyQuote {
                id
                slice_type
                primary {
                  quote {
                    raw
                    text
                  }
                }
              }
            }
            body1 {
              ... on PrismicProjectPageBody1OneColumn {
                id
                primary {
                  heading {
                    raw
                    text
                  }
                  num {
                    raw
                    text
                  }
                }
                items {
                  content {
                    raw
                    text
                  }
                  title {
                    raw
                    text
                  }
                }
                slice_type
              }
              ... on PrismicProjectPageBody1TwoColumn {
                id
                primary {
                  num {
                    raw
                    text
                  }
                  image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  heading {
                    raw
                    text
                  }
                }
                items {
                  content {
                    raw
                    text
                  }
                  title {
                    raw
                    text
                  }
                }
              }
              ... on PrismicProjectPageBody1FullImage {
                id
                slice_type
                items {
                  full_image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
              }
              ... on PrismicProjectPageBody1Image {
                id
                slice_type
                items {
                  image {
                    fluid(maxWidth: 1200) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
              }
              ... on PrismicProjectPageBody1Quote {
                id
                slice_type
                primary {
                  quote {
                    raw
                    text
                  }
                }
              }
            }
            order1 {
              raw
              text
            }
            project_description {
              raw
              text
            }
            project_name {
              raw
              text
            }
            role {
              raw
              text
            }
            timeline {
              raw
              text
            }
            type {
              raw
              text
            }
            tool {
              raw
              text
            }
          }
        }
      }
    `
  )
  return SpaceCenterData.data
}

export default useSpaceCenterData
